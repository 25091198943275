<template>
  <v-container class="pa-0">
    <v-row>
      <v-col
        cols="5"
      >
        <v-text-field
          v-model="nouveauType"
          label="Nouveau Type"
          outlined
          solo
        />
      </v-col>
      <v-col
        cols="5"
      >
        <v-btn
          big
          color="edit"
          @click="AddType()"
        >
          Enregistrer
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        v-for="typeVehicule in allDifferentTypeVehicule"
        :key="typeVehicule.id"
        md="4"
        xl="3"
      >
        <v-text-field
          v-model="typeVehicule.type"
          label="Solo"
          solo
          readonly
          class="float-left mr-3"
        />
        <v-btn
          color="success"
          @click="RemoveOneType(typeVehicule.type)"
        >
          <v-icon
            background-color="success"
          >
            mdi-close
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="5"
      >
        <v-btn
          big
          color="edit"
          @click="synchronisationTypeVehicule()"
        >
          Synchroniser
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

  import Vue from 'vue'
  import axios from 'axios'
  import VueAxios from 'vue-axios'

  Vue.use(VueAxios, axios)

  export default {
    data: () => ({
      date: null,
      trip: {
        name: '',
        location: null,
        start: null,
        end: null,
      },
      locations: [],
      allColor: [],
      allDifferentTypeVehicule: [],
      nouveauType: '',
      valeurConfigInitial: '',
    }),
    created () {
      Vue.axios.get('https://api.espace-jantes.com/GetConfigVehicules')
        .then((response) => {
          response.data.forEach(element => {
            if (element.Nom_config === 'differentTypeVehicule') {
              var valeurConfigSplit = element.valeur_config.split(',')
              this.valeurConfigInitial = element.valeur_config
              valeurConfigSplit.forEach(type => {
                this.allDifferentTypeVehicule.push({ type: type })
              })
            }
          })
        })
        .catch(error => console.log(error))
    },
    mounted () {
      if (this.$localStorage.get('connected') == null) {
        this.$router.push({ path: '/connexion' })
      }
      // console.log(this.allDifferentTypeVehicule)
    },
    methods: {
      AddType () {
        if (this.nouveauType.length > 0) {
          var nouvelleConfig = this.valeurConfigInitial + ',' + this.nouveauType
          Vue.axios.post('https://api.espace-jantes.com/AddOneTypeVehicule',
                         {
                           params: { nouveauTypeVehicule: nouvelleConfig },
                         },
          )
            .then((response) => {
              this.$router.go(0)
            })
        } else {
          this.$router.go(0)
        }
      },
      synchronisationTypeVehicule () {
        Vue.axios.get('https://api.espace-jantes.com/SynchroniserTypeVehicule')
          .then((response) => {
            this.$router.go(0)
          })
      },
      RemoveOneType (Type) {
        var nouvelleConfig = ''
        this.allDifferentTypeVehicule.forEach(element => {
          if (element.type === Type) {
            return
          }
          nouvelleConfig += element.type + ','
        })
        nouvelleConfig = nouvelleConfig.slice(0, -1)
        if (nouvelleConfig.length > 0) {
          Vue.axios.post('https://api.espace-jantes.com/AddOneTypeVehicule',
                         {
                           params: { nouveauTypeVehicule: nouvelleConfig },
                         },
          )
            .then((response) => {
              this.$router.go(0)
            })
        } else {
          this.$router.go(0)
        }
      },
    },
  }
</script>
